<template>
  <div class="contract-detail">
    <app-header :title="title" :isShowBack="true"></app-header>
    <app-list :opt="opt" @get="onGet" ref="list"></app-list>
  </div>
</template>
<script>
export default {
  data() {
    let _this = this;
    return {
      title: "车辆进出记录",
      opt: {
        columns: [
          {
            key: "carNo",
            label: "车牌号码"
          },
          {
            key: "passType",
            label: "进出类型"
          },
          {
            key: "passTime",
            label: "时间"
          },
          {
            key: "apartmentName",
            label: "房源名称"
          },
          {
            key: "barrierIp",
            label: "闸机IP"
          }
        ]
      }
    };
  },
  methods: {
    //车辆进出记录
    onGet(opt) {
      let dto = {
        pageNum: opt.skip,
        pageSize: opt.limit,
        userId: this.$route.query.id || ""
      };
      let url = "tenant-service/park/listPassRecord";
      this.post(url, dto, {
        isUseResponse: true
      }).then(res => {
        for (let i = 0; i < res.data.data.length; i++) {
          let item = res.data.data[i];
          item.passType = ["入闸", "出闸"][item.passType - 1];
        }
        opt.cb(res.data);
      });
    }
  }
};
</script>
<style lang="scss">
.contract-detail__box {
  margin: 0 auto;
  max-width: 900px;
}
</style>
